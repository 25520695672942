.mainButton {

    border: none;
    padding: 1rem;
    min-width: 10rem;
    min-height: 2rem;
    font-size: large;
    color: rgb(255, 255, 255);
    background-color: rgb(156, 16, 16);
    cursor: pointer;
    border-radius: 1rem;
  }
  
  .mainButton:hover {
    background-color: rgb(14, 56, 136);
    color: white;
    transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
  }

  .content-container {
    display: flex;          /* Set the container to flex */
    flex-wrap: wrap;        /* Allows the flex items to wrap onto multiple lines if needed */
    align-items: center;    /* Aligns items vertically in the center */
    justify-content: space-between; /* Creates equal space between the flex items */
}

.image-container img {
    max-width: 50%;         /* Set max-width to prevent image from taking full width on larger screens */
}

/* Mobile View: Stack content and image vertically */
@media (max-width: 768px) {
    .content-container {
        flex-direction: column;  /* Stacks the flex items vertically */
    }

    .image-container img {
        max-width: 100%;
    }
}