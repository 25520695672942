.home{
    height: max-content;
    width: 100%;
    -webkit-user-select: none;
    user-select: none;
}
.containerMain{
    background: linear-gradient(109.6deg, rgb(61, 121, 176) 11.3%, rgb(35, 66, 164) 91.1%);
}

.mainImage{
    min-height: 25rem;
    width: 100%;
    /* background: linear-gradient(90deg, rgba(21,85,160,0.4) 0%, rgba(68,116,180,0.5) 50%, rgba(172,30,30,0.7) 100%); */
    max-width: 1400px;
    margin: 0px auto;

    display: flex;
    flex-direction: row;
    align-items: center;
    flex: 1;
    padding: 3rem 3rem;
    justify-content: space-between;
    gap: 20px
    /* color: skyblue */
}

.mainImageComponent{
    flex: 1.5;
    
    overflow: hidden;
}

.mainImageComponentText{
    flex: 1;
}

.mainProductImage{
    width: 100%;
    border-radius: 2rem;
}

.mainImageText{
    /* padding-left: 5rem;
    padding-right: 5rem; */
    height: 100%;
    flex: 1
}

.description{
    color: #fff;
    margin: 10px 0px;
    font-size: large;
    flex: 1;
    padding-bottom: 1rem;
}

.content{
    color: #fff;
    font-size: large;
}

.heading{
    font-size: 1.8rem;
    font-weight: bolder;
    color: white
}

.para{
    margin-top: 0rem;    
    font-size: 1.5rem;
    color: white
  }



  .mainButton {

    border: none;
    padding: 1rem;
    min-width: 10rem;
    min-height: 2rem;
    font-size: large;
    color: rgb(255, 255, 255);
    background-color: rgb(156, 16, 16);
    cursor: pointer;
    border-radius: 1rem;
    border-top-left-radius: 0;
  }
  
  .mainButton:hover {
    background-color: rgb(14, 56, 136);
    color: white;
    transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
  }
  




@media (max-width: 990px) {
    .mainImage {
        flex-direction: column; /* Keep it as column for small screens */
        height: max-content;
        padding: 2rem 10px !important
    }
    .mainImageText {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100%; /* Ensure the div takes up the full height of its parent */
        padding: 0;
        width: 80vw;
        margin-right: 10vw;
        margin-left: 10vw;
        margin-top: 1rem;
        margin-bottom: 1rem;
    }

    .heading{
        font-size: 1.5rem;
        color: white
    }
    .mainProductImage{
        height: auto;
        width: 80vw;
        margin-right: 10vw;
        margin-left: 10vw;
        margin-top: 1rem;
        margin-bottom: 1rem;
    }
    .para{
      margin-top: 1rem;
      text-align: center;
      
      color: white
    }
    .mainButton {

        border-radius: 1rem;
      }

}


@media (max-width: 500px) {
    .heading{
        font-size: 1.5rem;
    }
}