.pricingTable {
  text-align: center;
  background: #fff;
  margin: 0 -15px;
  box-shadow: 0 10px 10px rgba(3, 16, 41, 0.5);
  padding-bottom: 40px;
  border-radius: 10px;
  color: #cad0de;
  transform: scale(1);
  transition: all 0.5s ease 0s;
  border: 10px solid;
}

.pricingTable:hover {
  transform: scale(1.05);
  z-index: 1;
  cursor: pointer;
}

.pricingTable .pricingTable-header {
  padding: 40px 0;
  background: #f5f6f9;
  border-radius: 10px 10px 50% 50%;
  transition: all 0.5s ease 0s;
}

.pricingTable:hover .pricingTable-header {
  background: #ff9624;
}

.pricingTable .pricingTable-header i {
  font-size: 40px;
  color: #858c9a;
  margin-bottom: 10px;
  transition: all 0.5s ease 0s;
}

.pricingTable .price-value {
  font-size: 20px;
  color: #ff9624;
  transition: all 0.5s ease 0s;
}

.pricingTable .month {
  display: block;
  font-size: 14px;
  color: darkgray;
}

.pricingTable:hover .month,
.pricingTable:hover .price-value,
.pricingTable:hover .pricingTable-header i {
  color: #fff;
}

.pricingTable .heading {
  color: #ff9624;
  margin-bottom: 20px;
  text-transform: uppercase;
}
.pricing-content {
  min-height: 260px;
}

.pricingTable .pricing-content ul {
  list-style: none;
  padding: 0;
  margin-bottom: 30px;
}

.pricingTable .pricing-content ul li {
  line-height: 30px;
  color: #a7a8aa;
}

.pricingTable .pricingTable-signup a {
  display: inline-block;
  font-size: 15px;
  color: #ffffff;
  padding: 10px 35px;
  border-radius: 20px;
  background: #ffa442;
  text-transform: uppercase;
  transition: all 0.3s ease 0s;
}

.pricingTable .pricingTable-signup a:hover {
  box-shadow: 0 0 10px #ffa442;
}

.pricingTable.blue .heading,
.pricingTable.blue .price-value {
  color: #4b64ff;
}

.pricingTable.blue .pricingTable-signup a,
.pricingTable.blue:hover .pricingTable-header {
  background: #4b64ff;
}

.pricingTable.blue .pricingTable-signup a:hover {
  box-shadow: 0 0 10px #4b64ff;
}

.pricingTable.red .heading,
.pricingTable.red .price-value {
  color: #ff4b4b;
}

.pricingTable.red .pricingTable-signup a,
.pricingTable.red:hover .pricingTable-header {
  background: #ff4b4b;
}

.pricingTable.red .pricingTable-signup a:hover {
  box-shadow: 0 0 10px #ff4b4b;
}

.pricingTable.green .heading,
.pricingTable.green .price-value {
  color: #40c952;
}

.pricingTable.green .pricingTable-signup a,
.pricingTable.green:hover .pricingTable-header {
  background: #40c952;
}

.pricingTable.green .pricingTable-signup a:hover {
  box-shadow: 0 0 10px #40c952;
}

.pricingTable.blue:hover .price-value,
.pricingTable.green:hover .price-value,
.pricingTable.red:hover .price-value {
  color: #fff;
}

@media screen and (max-width: 990px) {
  .pricingTable {
    margin: 0 0 20px;
  }
  .pricing-content {
    min-height: auto;
    padding: 10px;
  }
}
