.review-card {
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    padding: 20px;
    margin-bottom: 20px;
    text-align: center;
    transition: transform 0.2s ease-in-out;
    width: 100%;
    height: 30rem;
}

.review-card:hover {
    transform: translateY(-5px);
}

.profile-image {
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
}

.review-name {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 5px;
}

.review-location {
    font-size: 14px;
    color: #999;
    margin-bottom: 10px;
}

.rating-stars {
    list-style: none;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
}

.rating-stars li {
    margin-right: 5px;
}

.rating-value {
    margin-left: 10px;
}

.review-content {
    font-size: 16px;
    line-height: 1.5;
    position: relative;
}

.read-more {
    color: #007bff;
    cursor: pointer;
    position: absolute;
    bottom: 0;
    right: 0;
    display: flex;
    align-items: center;
}
