.container {
    width: 100%;
    max-width: 1400px;
    margin: 2rem auto;
    justify-content: center;
  }
  
  .package-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 2rem;
    width: 100%;
    place-items: center !important;
    align-items: stretch !important;
    padding: 1rem;
  }

  
  @media (max-width: 990px) {
    .container {
      margin: 1rem auto;
    }

    .package-container {
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 1rem;
      padding: 1rem 10px;
    }
  
  }

  @media (max-width: 700px) {

    .package-container {
      grid-template-columns: repeat(1, 1fr);
      grid-gap: 1rem;
      padding: 1rem 10px;
    }
  }
  
  @keyframes fadeIn {
    from {
      /* opacity: 0; */
      transform: scale(0.7);
    }
    to {
      /* opacity: 1; */
      transform: scale(1.05);
    }
  }
  
  
  
  
  .package {
    position: relative;
    overflow: hidden;
    height: 400px;
    width: 300px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.6);
  }
  
  .package img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.3s ease;
  }
  
  .package:hover img {
    transform: scale(1.1);
  }

  .package div{
    position: absolute;
    bottom: 0;
    left: 0;
    margin: 0;
    height: 100%;
    width: 100%;
    /* height: 80px; */
    padding: 1rem;
    background-color: rgba(0, 0, 0, 0.6);
    color: #fff;
    font-size: 1.2rem;
    font-weight: bold;
    text-align: center;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  
  .package .title {    
    position: absolute;
    height: 100%;
    bottom: 0;
    left: 0;
    margin: 0;
    width: 100%;
    /* height: 80px; */
    padding: 1rem;
    /* background-color: rgba(0, 0, 0, 0.9); */
    color: #fff;
    font-size: 1.2rem;
    font-weight: bold;
    text-align: center;
    transition: transform 0.3s ease;
    transform: translateY(60%);
  }
  
  .package:hover .title {
    transform: translateY(0);
  }

  .package .price {
    position: relative;
    bottom: 0;
    left: 0;
    width: 100%;
    margin-top: 0;
    margin-bottom: 1rem;
    background-color: none;
    color: #fff;
    font-size: 1rem;
    font-weight: bold;
    text-align: center;
    transition: transform 0.3s ease;
    /* transform: translateY(200%); */
  }

  .package:hover .price {
    transform: translateY(60%);
  }


  