.card{
margin: 2rem 0rem;
color: #111b5d;
cursor: pointer;
}

.card:hover{
    color: #3b4585
}

.date{
opacity: 0.7;
}

.title{
    font-size: 1.3rem;
    font-weight: bolder;
}

.description{
    opacity: 0.7;
}

@media (max-width: 700px) {


    .title{
        font-size: 1.1rem;
    }
  }