.card {
    flex: 1;
    max-width: 500px;
    min-width: 250px;
    border: 2px solid rgba(34, 81, 255, 0.6);
    border-radius: 10px;
    overflow: hidden;
    transition: all 0.2s ease-in-out;
    cursor: pointer;
    position: relative; /* Needed for pseudo-element positioning */
    padding-bottom: 2rem;
    text-align: left !important;
}

.card:hover {
    transform: translateY(-10px);
}

.card:hover .readMore::after {
    height: 5px;
    background-color: rgba(34, 81, 255, 0.8);
}

.cardImageContainer {
    height: 300px;
    width: 100%;
}

.cardContent {
    padding: 1rem;
}

.cardDate {
    margin: 20px 0 10px 0;
    color: #5e5e5e;
}

.cardTitle {
    margin: 0 0 20px 0;
    color: #1e1e1e;
    font-size: 1.3rem;
    font-weight: bold;
}

.readMore {
    font-weight: bold;
    color: #1e1e1e;
    text-decoration: none;
    position: relative; /* Needed for pseudo-element positioning */
}

.readMore::after {
    content: '';
    display: block;
    width: 80px;
    height: 3px;
    background-color: rgba(34, 81, 255, 0.6);
    position: absolute;
    bottom: -3px; /* Adjust if necessary */
    left: 0;
    transition: height 0.2s ease-in-out;
}
