.pricingCardHeight {
  height: 295px;
}
.pricingCard:hover {
  box-shadow: 0px 10px 10px 5px rgba(80, 80, 80, 0.5);


}

.pricingCard:hover .price {
  font-size: 32px;
}

.selectPlan {
  box-shadow: 0px 10px 10px 5px rgba(80, 80, 80, 0.5);


}
