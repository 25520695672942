.container{
    padding: 1rem;
    padding-top: 3rem;
    margin: 0px auto;
    max-width: 1000px;
}

h1{
    font-weight: bolder;
}

h3{
    font-weight: bolder;
}

h4{
    font-weight: bolder;

}

p{
    font-size: 1.1rem;
}