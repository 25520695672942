.color{
    background:linear-gradient(109.6deg, rgb(77, 137, 194) 11.3%, rgb(50, 82, 187) 91.1%)
}


/* The switch - the box around the slider */
.switch {
    font-size: 17px;
    position: relative;
    display: inline-block;
    width: 3em;
    height: 1.5em;
  }
  
  /* Hide default HTML checkbox */
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  /* The slider */
  .slider {
    position: absolute;
    cursor: pointer;
    inset: 0;
    background: #9fccfa;
    border-radius: 50px;
    transition: all 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  }
  
  .slider:before {
    position: absolute;
    content: "";
    display: flex;
    align-items: center;
    justify-content: center;
    height: 1.5em;
    width: 1.5em;
    inset: 0;
    background-color: white;
    border-radius: 50px;
    box-shadow: 0 10px 20px rgba(0,0,0,0.4);
    transition: all 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  }
  
  .switch input:checked + .slider {
    background: #0974f1;
  }
  
  .switch input:focus + .slider {
    box-shadow: 0 0 1px #0974f1;
  }
  
  .switch input:checked + .slider:before {
    transform: translateX(1.6em);
  }


  
  .pricingPageButton {
    background: linear-gradient(to bottom, #2c5291, #2b497b); /* Darker gradient */
    color: white; /* White text color */
    padding: 10px 20px; /* Padding around the text */
    border: 3px solid #fff; /* White border */
    border-radius: 10px; /* Rounded corners */
    font-size: 16px; /* Font size */
    text-align: center; /* Center the text */
    text-decoration: none; /* Remove underline from text */
    display: inline-block; /* Display as inline block */
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2), 0 4px 12px rgba(0, 0, 0, 0.3); /* Inner and outer shadow */
    transition: all 0.3s ease; /* Smooth transition for hover effect */
    position: relative; /* Position relative for pseudo-elements */
    height: max-content; /* Ensure height adjusts to content */
}

.pricingPageButton::before {
    content: 'Register Now';
    position: absolute;
    top: 5px;
    left: 5px;
    right: 5px;
    bottom: 5px;
    border-radius: 5px; /* Rounded corners */
    background: linear-gradient(to bottom, #2c5291, #2b497b); /* Same gradient background */
    border: 3px solid #fff; /* White border */
    display: flex; /* Use Flexbox */
    align-items: center; /* Center vertically */
    justify-content: center; /* Center horizontally */
    z-index: 1; /* Place behind the button content */
}

.pricingPageButton:hover {
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3), 0 6px 20px rgba(0, 0, 0, 0.35); /* Increase shadow on hover */
}



.getStartedButton {
  background: linear-gradient(to bottom, #2c5291, #2b497b); /* Darker gradient */
  color: white; /* White text color */
  padding: 10px 20px; /* Padding around the text */
  border: 3px solid #fff; /* White border */
  border-radius: 10px; /* Rounded corners */
  font-size: 16px; /* Font size */
  text-align: center; /* Center the text */
  text-decoration: none; /* Remove underline from text */
  display: inline-block; /* Display as inline block */
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2), 0 4px 12px rgba(0, 0, 0, 0.3); /* Inner and outer shadow */
  transition: all 0.3s ease; /* Smooth transition for hover effect */
  position: relative; /* Position relative for pseudo-elements */
  height: max-content; /* Ensure height adjusts to content */
}

.getStartedButton::before {
  content: 'Get Started';
  position: absolute;
  top: 5px;
  left: 5px;
  right: 5px;
  bottom: 5px;
  border-radius: 5px; /* Rounded corners */
  background: linear-gradient(to bottom, #2c5291, #2b497b); /* Same gradient background */
  border: 3px solid #fff; /* White border */
  display: flex; /* Use Flexbox */
  align-items: center; /* Center vertically */
  justify-content: center; /* Center horizontally */
  z-index: 1; /* Place behind the button content */
}

.getStartedButton:hover {
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3), 0 6px 20px rgba(0, 0, 0, 0.35); /* Increase shadow on hover */
}


.cardStyle{
  box-shadow: none !important;
  transition: all 0.3s ease-in-out !important;
  position: relative !important;
  z-index: 1 !important;
}

.cardStyle:hover{
  box-shadow: 0px 5px 50px rgba(0,0,0,0.4) !important;
  z-index: 2 !important;
}


.topPricingButton{
  min-width: 300px;
  padding: 1rem;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  opacity: 1;
  flex: 1;
  border-radius: 10px;
}