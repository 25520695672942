#imageByContentCard {
  box-shadow: -15px 15px 0px 0px #1555a0;
}

#imageByContentCard .cardSideSection {
  display: flex;
  align-items: center;
}

/* CSS style for center-content */
#imageByContentCard .cardSideSectionImage {
  text-align: center; /* Optional: To center the text content horizontally */
  margin: auto; /* Optional: To center the nested div within the flex container */
  font-size: 40px;
  color: #4071b6;
}
