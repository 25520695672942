.contact-page {
    font-family: 'Helvetica Neue', Arial, sans-serif;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  
  .header {
    background-color: #1f42a0;
    color: #ffffff;
    padding: 1rem 0;
    margin-bottom: 2rem;
  }
  
  .header h1 {
    font-size: 2rem;
  }
  
  .contact-content {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    max-width: 1500px;
  }
  
  .contact-info,
  .contact-form,
  .contact-form-second {
    flex: 1;
    padding: 2rem;
    padding-bottom: 0;
    margin-bottom: 0;
  }
  
  .contact-form {
    text-align: left;
  }
  .contact-form-second {
    text-align: left;
  }
  
  .form-group {
    margin-bottom: 1rem;
  }
  
  .form-group label {
    display: block;
    font-weight: bold;
  }
  
  .form-group input,
  .form-group textarea {
    width: 100%;
    padding: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 1rem;
    flex: 1;
    height: 100%;
    min-height: 5rem;
    border-radius: 15px;
    padding: 1rem
  }
  
  .form-group textarea {
    resize: vertical;
  }
  
  button {
    background-color: #1f42a0;
    color: #ffffff;
    border: none;
    padding: 0.5rem 1rem;
    border-radius: 15px;
    cursor: pointer;
    font-size: 1rem;
  }
  
  button:hover {
    background-color: #123578;
  }
  
  .contact-image {
    width: 100%;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  }
  
  .radio-group {
    display: flex;
    align-items: center;
    margin: 0.5rem 0;
  }
  
  .radio-group input[type="radio"] {
    margin-right: 0.5rem;
  }

  .additional{
    background-color: #ffffff;
    /* background-color: #fcedea; */
    padding: 1rem;
    margin: 1rem;
    margin-top: 3rem;
  }
  
  /* Add responsive styles for the contact form */
  @media (max-width: 768px) {
    .contact-content {
      flex-direction: column;
      align-items: center;
    }
  
    .contact-form {
      margin-top: 2rem;
      /* width: 90vw; */
      padding: 1rem;
      width: auto;
      margin-bottom: 0rem;
    }

    .additional{
      background-color: #ffffff;
      padding: 1rem;
      margin: 0rem;
      margin-top: 3rem;
    }

    .contact-form-second {
        margin-top: 0rem;
        width: 90vw;
        height: 100%;
        background-color: #303030;
      }
  
    .responsive-students-image {
      max-width: 80%;
      height: auto;
    }
  
    .form-container {
      width: 100%;
    }
  
    .contact-form {
      text-align: center;
    }
  }
  