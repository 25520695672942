#howItWork {
  margin-top: 80px !important;
}

#howItWork .cardIcon {
  font-size: 50px;
  color: #1555a0;
}

#howItWork .card{
  transform: scale(1);
  transition: transform 0.3s ease-in-out;
}


#howItWork .card:hover {
  transform: scale(1.03);
}


#howItWork .card:hover .cardIcon {
  color: white;
}

#howItWork .cardIcon:hover {
  transform: scale(1.1);
  fill: white; /* or color: white; depending on the element */
}
