/* Details.module.css */

.container {
  max-width: 1800px;
  margin: 0px auto;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  gap: 20px
}

.blog-container {
  padding: 2rem;
  flex: 1;
  text-align: center;
}

.relatedBlogsContainer{
  flex: 0.6;
  padding: 2rem;
  background: #eee
}

.blog-title {
  font-size: 2rem;
  font-weight: bold;
  text-align: center;
  margin-top: 0;
}

.headerContainer {

  padding: 1rem;
  text-align: center;
  font-size: 1.5rem;
  font-weight: bold;
  color: white;
}



.blog-image {
  width: 100%;
  margin-bottom: 20px;
  
}

.buttonContainer {
  text-align: center;
  margin-top: 1rem;
}

.myButton {
  background-color: rgb(10, 21, 46);
  color: white;
  font-size: 1.2rem;
  padding: 1rem 2rem;
  border: none;
  border-radius: 8px;
  transition: all 0.3s ease-in-out;
  font-weight: 500;
  cursor: pointer;
}

.myButton:hover {
  transform: scale(1.1);
  background-color: rgb(23, 40, 82);
}



.ql-align-center {
  text-align: center !important;
}

.ql-align-right {
  text-align: right !important;
}

.ql-align-left {
  text-align: left !important;
}

.ql-align-justify {
  text-align: justify !important;
}



.blogsButton {
  background: linear-gradient(to bottom, #2c5291, #2b497b); /* Darker gradient */
  color: white; /* White text color */
  padding: 10px 20px; /* Padding around the text */
  border: 3px solid #fff; /* White border */
  border-radius: 10px; /* Rounded corners */
  font-size: 16px; /* Font size */
  text-align: center; /* Center the text */
  text-decoration: none; /* Remove underline from text */
  display: inline-block; /* Display as inline block */
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2), 0 4px 12px rgba(0, 0, 0, 0.3); /* Inner and outer shadow */
  transition: all 0.3s ease; /* Smooth transition for hover effect */
  position: relative; /* Position relative for pseudo-elements */
  height: max-content; /* Ensure height adjusts to content */
  transform: translateY(-150px);
  margin-bottom: 50px;
}

.blogsButton::before {
  content: 'Read Full';
  position: absolute;
  top: 5px;
  left: 5px;
  right: 5px;
  bottom: 5px;
  border-radius: 5px; /* Rounded corners */
  background: linear-gradient(to bottom, #2c5291, #2b497b); /* Same gradient background */
  border: 3px solid #fff; /* White border */
  display: flex; /* Use Flexbox */
  align-items: center; /* Center vertically */
  justify-content: center; /* Center horizontally */
  z-index: 1; /* Place behind the button content */
}

.blogsButton:hover {
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3), 0 6px 20px rgba(0, 0, 0, 0.35); /* Increase shadow on hover */
}


@media (max-width: 990px) {

  .container {
    flex-direction: column;
  }

  .blogsButton {
    transform: translateY(0px);
    margin: 20px auto;
  }
}
