.footerContainer{
  background-color: #191919;
  flex: 1
}


.footer {
  color: #fff;
  margin: 0px auto;
  left: 0;
  bottom: 0;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  padding: 1rem;
  font-size: 1.2rem;
  max-width: 1800px;
}

.grid-item {
  padding: 20px;
  text-align: left;
  flex: 1;
}

@media (max-width: 1000px) {
  .footer {
    grid-template-columns: 1fr;
  }
  
}