
.month-container {
    display: flex;
    justify-content: center;
    margin: 0 auto;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 10px;
    margin-top: 2rem;
  }

  .categoryCol {
    box-shadow: none !important;
    transition: all 0.3s ease-in-out !important;
    position: relative !important;
    z-index: 1 !important;
    border-radius: 2rem !important;
    overflow: hidden;
  }

  @media screen and (max-width: 1285px) {
    .categoryCol {
      flex: 1 0 calc(50% - 10px); /* Set the width for two items per row on smaller screens */
      max-width: 400px;
    }
  }

  @media screen and (max-width: 650px) {
    .categoryCol {
      flex: 1 0 calc(100% - 10px); /* Set the width for two items per row on smaller screens */
    }
  }
  
  .category {
    display: flex;
    font-size: 1.3rem;
    font-weight: bold;
    min-width: 200px;
  }



  .categoryCol:hover {
    box-shadow: 0px 5px 50px rgba(0,0,0,0.4) !important;
    z-index: 2 !important;
  }
  
  .category div {
    flex: 1;
    text-align: center;
    padding: 1rem;
  }
  
  .category-a {
    background-color: rgb(155,190,230); /* Category A - Yellow */
    border-top-right-radius: 1.5rem;
    border-top-left-radius: 1.5rem;
  }
  
  .category-b {
    background-color: rgb(248,203,173); /* Category B - Blue */
    border-top-right-radius: 1.5rem;
    border-top-left-radius: 1.5rem;
  }
  
  .category-c {
    background-color: rgb(198,224,180); /* Category C - Purple */
    border-top-right-radius: 1.5rem;
    border-top-left-radius: 1.5rem;
  }
  
  .category-d {
    background-color: rgb(172,185,202); /* Category D - Red */
    border-top-right-radius: 1.5rem;
    border-top-left-radius: 1.5rem;
  }
  
  .category-e {
    background-color: rgb(255,230,153); /* Category E - Green */
    border-top-right-radius: 1.5rem;
    border-top-left-radius: 1.5rem;
  }
  
  .category-f {
    background-color: rgb(174,170,170); /* Category F - Pink */
    border-top-right-radius: 1.5rem;
    border-top-left-radius: 1.5rem;
  }
  
  .category-g {
    background-color: rgb(198,224,180); /* Category G - Turquoise */
    border-top-right-radius: 1.5rem;
    border-top-left-radius: 1.5rem;
  }
  

  .revisionPageButton {
    background: linear-gradient(to bottom, #2c5291, #2b497b); /* Darker gradient */
    color: white; /* White text color */
    padding: 10px 20px; /* Padding around the text */
    border: 3px solid #fff; /* White border */
    border-radius: 10px; /* Rounded corners */
    font-size: 16px; /* Font size */
    text-align: center; /* Center the text */
    text-decoration: none; /* Remove underline from text */
    display: inline-block; /* Display as inline block */
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2), 0 4px 12px rgba(0, 0, 0, 0.3); /* Inner and outer shadow */
    transition: all 0.3s ease; /* Smooth transition for hover effect */
    position: relative; /* Position relative for pseudo-elements */
    height: max-content; /* Ensure height adjusts to content */
}

.revisionPageButton::before {
    content: 'Click here to Register';
    position: absolute;
    top: 5px;
    left: 5px;
    right: 5px;
    bottom: 5px;
    border-radius: 5px; /* Rounded corners */
    background: linear-gradient(to bottom, #2c5291, #2b497b); /* Same gradient background */
    border: 3px solid #fff; /* White border */
    display: flex; /* Use Flexbox */
    align-items: center; /* Center vertically */
    justify-content: center; /* Center horizontally */
    z-index: 1; /* Place behind the button content */
}

.revisionPageButton:hover {
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3), 0 6px 20px rgba(0, 0, 0, 0.35); /* Increase shadow on hover */
}

