@media only screen and (max-width: 1300px) {
  .largeView {
    display: none !important;
  }
}

@media only screen and (min-width: 1300px) {
  .mobileView {
    display: none !important;
  }
}

.review-section {
  padding: 50px 0;
  background-color: #2b2b2b;
}

.review-carousel {
  display: flex;
  overflow: hidden;
  justify-content: center;
  align-items: center;
  max-width: 100%;
  margin: 0 auto;
}

.review-card {
  display: none;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  transition: opacity 0.3s ease-in-out;
  opacity: 0.2;
}

.review-card.active {
  display: block;
  opacity: 1;
}

.review-card .quote-icon {
  font-size: 24px;
  margin: 0 10px;
}

.review-card .review-content {
  font-size: 16px;
  line-height: 1.6;
}

.review-card .review-content::before {
  content: open-quote;
}

.review-card .review-content::after {
  content: close-quote;
}
