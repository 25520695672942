#understandIB .flip-card {
  background-color: transparent;
  perspective: 1000px;
  height: 250px;
  position: relative;
}

#understandIB .flip-card-front, #understandIB .flip-card-back {
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden; /* Corrected this line */
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  transition: transform 0.5s;
  display: flex;
  justify-content: center;
  align-items: center;
}


#understandIB .flip-card-front {
  z-index: 2;
}

#understandIB .flip-card:hover .flip-card-front {
  transform: rotateY(180deg);
}

#understandIB .flip-card-back {
  transform: rotateY(180deg);
  background-color: rgba(14, 56, 136, 0.9);
  color: white;
}

#understandIB .flip-card:hover .flip-card-back {
  transform: rotateY(0deg);
}
