.navbarbottom {
  position: fixed !important;
  width: 100% !important;
  left: 0 !important;
  top: 0 !important;
  right: 0 !important;
  justify-content: space-around !important;
  align-items: center !important;
  z-index: 999;
  box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.1); 
}
.nav-item {
  padding: 5px;
  font-size: 16px;
  cursor: pointer;
}

.LogoImg {
  width: 169px;
}
.LogoLink {
  margin-left: 5%;
  cursor: pointer;
}
.navElement {
  margin-right: 5%;
}

.nav-item.active .nav-link {
  color: #1e1e1e;
}

@media (max-width: 576px) {
  .LogoImg {
    width: 177px;
  }
  .LogoLink {
    margin-left: 1%;
  }
}

.navButtonMain{
  padding: 10px;
  background-color: #fff;
  border: none;
  color: white;
  outline: none;
  border-radius: 0.5rem;
}

.navButtonMain:hover {
  background-color: rgb(156, 16, 16);
  color: white;
  transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
}