@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@300;400;500;600;700&display=swap');


#root {
  margin: 0;
  overflow-x: hidden;
  user-select: auto !important;
  -webkit-user-select: auto !important;
}

::-webkit-scrollbar {
  width: 8px;
  height: 1px;
}
/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  /* background: #4071B6; */
  background: #191919
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #2b2b2b
}
