#findATutor .findTutor-container {
  position: relative;
  width: 100%;
  height: max-content;
  background: linear-gradient(69.7deg, rgb(216, 81, 82) 40%, rgb(154, 27, 69) 100.1%);
  overflow: hidden;
}

.thisContainer{
  flex: 1;
  margin: 0px auto;
  max-width: 1800px;
  min-height: 25rem;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

#findATutor .bg-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("../../assets/CoverLMS.jpg"); /* Replace with your image path */
  background-size: cover;
  background-position: center;
  opacity: 0; /* Adjust the opacity as needed */
}

#findATutor .text-container {
  position: relative;
  text-align: center;
  color: #323e6f;
  width: '100%'
}

#findATutor h1 {
  font-size: 30px;
  font-weight: bold;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5); /* Add shadow for better visibility */
}


.mainButtonOne {

  border: none;
  padding: 1rem;
  min-width: 12rem;
  min-height: 2rem;
  font-size: 1.2rem;
  color: rgb(255, 255, 255);
  background-color: rgb(156, 16, 16);
  cursor: pointer;
  border-radius: 1rem;
  border-top-left-radius: 0;
  border: 3px solid #ccc;
  transition: all 0.3s ease-in-out;
  box-shadow: none;
}

.mainButtonOne:hover {
  box-shadow: 0px 5px 15px rgba(0,0,0,0.3);
}


.mainButtonSecond {

  border: none;
  margin-right: 1rem;
  padding: 1rem;
  min-width: 12rem;
  min-height: 2rem;
  font-size: 1.2rem;
  color: rgb(255, 255, 255);
  background-color: rgb(14, 56, 136) ;
  cursor: pointer;
  border-radius: 1rem;
  border-top-right-radius: 0;
  border: 3px solid #ccc;
  transition: all 0.3s ease-in-out;
  box-shadow: none;
}

.mainButtonSecond:hover {
  box-shadow: 0px 5px 15px rgba(0,0,0,0.3);
  background-color: rgb(156, 16, 16);
}



@media (max-width: 576px) {
  #findATutor h1 {
    font-size: 25px !important;
  }

  .mainButtonOne{
    border-radius: 1rem;
    width: 70%
  }

  .mainButtonSecond{
    border-radius: 1rem;
    width: 70%;
    margin-bottom: 1rem;
    margin-right: 0rem;
  }

}





