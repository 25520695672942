.timelineContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  overflow-x: hidden; /* Hide any overflow */
}

.timelineImage {
  cursor: pointer;
  width: auto; /* Each image takes up 25% of the container's width */
  height: auto;
  transition: opacity 0.3s ease-in-out;
}

@media (max-width: 1400px) {
  .timelineImage {
    height: 300px;
  }
}

@media (max-width: 1250px) {
  .timelineImage {
    height: 250px;
  }
}

@media (max-width: 1100px) {
  .timelineImage {
    height: 200px;
  }
}

@media (max-width: 860px) {
  .timelineImage {
    height: 150px;
  }
}

@media (max-width: 675px) {
  .timelineImage {
    height: 110px;
  }
}

@media (max-width: 500px) {
  .timelineImage {
    height: 90px;
  }
}