.thisContainer {
  flex: 1;
  background: #14549f;
  /* background: linear-gradient(109.6deg, rgb(61, 121, 176) 11.3%, rgb(35, 66, 164) 91.1%); */
  padding: 0px !important;
}

.mainImage {
  height: 100vh;
  max-height: 800px;
  width: 100%;
  max-width: 1800px;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1;
  margin: 0px auto !important;
  overflow: hidden;
  padding: 0px !important;
  /* color: skyblue */
  background-image: url("./img/sssplatter-cropped.svg") !important;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: top right;
  background-position-x: 90%;
  background-position-y: 200px;
}

.mainImageComponent {
  flex: 1;
}

.mainImageComponentSecond {
  flex: 1;
}

.mainProductImage {
  height: auto;
  width: 90%;
  max-height: 90%;
  filter: drop-shadow(0px 25px 30px rgba(0, 0, 0, 0.5));
}

.mainImageText {
  padding-left: 5rem;
  padding-right: 5rem;
  height: 100%;
  flex: 1;
}

.heading {
  font-size: 5rem;
  margin-bottom: 0rem;
  font-weight: bolder;
  color: white;
}

.para {
  margin-top: 0rem;
  font-size: 1.5rem;
  color: white;
}

.mainButton {
  border: none;
  padding: 1rem;
  min-width: 10rem;
  min-height: 2rem;
  font-size: large;
  color: rgb(255, 255, 255);
  background-color: rgb(156, 16, 16);
  cursor: pointer;
  border-radius: 1rem;
  border-top-left-radius: 0;
  border: 3px solid #ccc;
  box-shadow: none;
  transition: all 0.3s ease-in-out;
}

.mainButton:hover {
  background-color: rgb(14, 56, 136);
  color: white;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.35);
}

@media (max-width: 990px) {

  .mainImage {
    flex-direction: column; /* Keep it as column for small screens */
    height: max-content;
    max-height: max-content;
    background-position-x: 90%;
    background-position-y: 150%;
  }
  .mainImageText {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%; /* Ensure the div takes up the full height of its parent */
    width: 80vw;
    margin-top: 3rem;

    padding-left: 1rem;
    padding-right: 1rem;
  }

  .mainButton {
    font-size: 1rem;
    padding: 0.5rem 1rem;
    border-radius: 5px !important;
    margin-bottom: 2rem;
  }

  .heading {
    font-size: 3.5rem;
    text-align: center;
    color: white;
  }
  .mainProductImage {
    height: auto;
    width: 80vw;
    margin-right: 10vw;
    margin-left: 10vw;
  }
  .para {
    margin-top: 1rem;
    text-align: center;
    color: white;
  }
  .mainButton {
    border-radius: 1rem;
  }
}

@media (max-width: 500px) {
  .heading {
    font-size: 3rem;
    text-align: center;
  }
}
