.testimonial-card .card-up {
    overflow: hidden;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
  }
  .testimonial-card .avatar {
    width: 200px;
    overflow: hidden;
    border: 3px solid #fff;
    border-radius: 50%;
  }

  .card-flip {
    transform-style: preserve-3d;
    height: 23rem;
    overflow: hidden;
    position: relative;
    border: none
    
}

.card-flip:hover .card-inner {
    transform: rotateY(180deg);
}

.card-inner {
    transform-style: preserve-3d;
    transition: transform 0.6s;
    position: relative;
    width: 100%;
    height: 100%;
    perspective: 1000px;  /* Moved here */
}

.card-front, .card-back {
    backface-visibility: hidden;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    display: flex;
    text-align: center;
    padding: 10px; 
    background-color: #eee;
    border-radius: 1rem;
}

.card-front {
    z-index: 2;
    transform: rotateY(0deg);
}

.card-back {
    transform: rotateY(-180deg);
}

/* ... Rest of your CSS ... */
