#rotatingCard .Rcard {
  perspective: 150rem;
  position: relative;
  height: 35rem;
  width: 100%;
  box-shadow: none;
  background: none;
}

#rotatingCard .Rcard-side {
  height: auto;
  border-radius: 15px;
  transition: all 0.5s ease;
  backface-visibility: hidden;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  color: white;
}

#rotatingCard .Rcard-side.back {
  transform: rotateY(-180deg);
  background-color: #4158d0;
  background-image: linear-gradient( 43deg, #4158d0 0%, #9a9ba1 46%, #70ddff26 100% );
}

#rotatingCard .Rcard-side.front {
  background-color: #0093e9;
  background-image: linear-gradient(160deg, #ede6e3 0%, #7b8180 100%);
}

#rotatingCard .Rcard:hover .Rcard-side.front {
  transform: rotateY(180deg);
}

#rotatingCard .Rcard:hover .Rcard-side.back {
  transform: rotateY(0deg);
}
